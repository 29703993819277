import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JamaatConfig } from 'src/environments/jamaatconfig';
import { TokenStorageService } from './token-storage.service';
import { Router } from '@angular/router';
import { AccessControl } from 'ts-access-control';
import { MemberData } from '../interfaces/memberdata.interface';

export enum permissionActions {
  read = "readAny",
  readOwn = 'readOwn',
  delete = "deleteAny",
  update = "updateAny",
  updateOwn = "updateOwn"
  
}

export enum permissionResources {
  member = "member",
  report = "report",
  broadcast = "broadcast",
  setting = "setting",
  thaali = "thaali",
  thaaliQty = "thaaliQty",
  cook = "cook",
  area = "area",
  events = "events",
  venue = "venue",
  attendanceDetail = "attendance",
  superadmin = "superadmin" // can manage member permission. makes it super admin
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient, private tokenService: TokenStorageService, private router: Router,    private jamaatConfig: JamaatConfig
    ) { }


  login(email: string, password: string, captcha: string): Observable<any> {
    const backend = this.jamaatConfig.currentConfig().backend;

    return this.http.post(`${backend}/login`, {
      'email': email,
      'password': password,
      'recaptcha': captcha
    });
  }

  logout () {

    this.tokenService.signOut().then(()=>{
      this.router.navigate(['']);

    });
    // navigate to root and let app decide if login screen or mobile app login screen
  }

  getLoggedInUser() {
    return this.tokenService.getUser();
  }

  isUserLoggedIn() : boolean {
    if (this.tokenService.getUser()?.auth)
      return true;
    else 
      return false;
  }

  getMemberUUID() : string {
    return this.tokenService.getMemberUUID();
  }

  saveMemberUUID(uuid: string) {
    return this.tokenService.saveUUID(uuid);
  }

  saveMemberJamaat(jamaat: string) {
    
    return this.jamaatConfig.saveJamaat(jamaat);
  }

  isAdminUser() : boolean {

    if ( this.tokenService.getUser()?.role === 'admin')
      return true;
    
    return false;

  }

  permitted(user: MemberData, resource: permissionResources, action: permissionActions) {

    const ac = new AccessControl(user.permissions);
    let role = user.role;
    switch (action) {
      case permissionActions.read:
         return ac.can(role).read(resource).granted;
      
      case permissionActions.update:
        return ac.can(role).update(resource).granted;

      case permissionActions.delete:
        return ac.can(role).delete(resource).granted;
      
      case permissionActions.updateOwn:
        return ac.can(role).updateOwn(resource).granted;
      
      case permissionActions.readOwn:
        return ac.can(role).readOwn(resource).granted;

      default:
        return false;
    }
  }

  // only works for logged in user
  canAccess(resource: permissionResources, action: permissionActions) : boolean {

    let user = this.tokenService.getUser();
    
    if (user?.permissions) {

      const ac = new AccessControl(user.permissions);

      
      switch (action) {
        case permissionActions.read:
           return ac.can(user.role).read(resource).granted;
        
        case permissionActions.update:
          return ac.can(user.role).update(resource).granted;

        case permissionActions.delete:
          return ac.can(user.role).delete(resource).granted;

        default:
          return false;
      }
      
    }
    else  
      return false;
  }

  
 
}